/* eslint-disable no-case-declarations */
import React from 'react';

import {Filter} from '../Filter/Filter';
import {SingleSelectionFilter} from '../SingleSelectionFilter/SingleSelectionFilter';
import {MultipleSelectionFilter} from '../MultipleSelectionFilter/MultipleSelectionFilter';
import {ColorFilter} from '../ColorFilter/ColorFilter';
import {FilterModel, FilterType, IFilterModel} from '../../../types/galleryTypes';
import {RangeFilter} from '../RangeFilter/RangeFilter';
import {FiltersHeader} from '../FiltersHeader/FiltersHeader';
import {PriceFilterModel} from '../../../models/PriceFilterModel';
import s from './Filters.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {FiltersFooter} from '../FiltersFooter/FiltersFooter';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {CollectionFilterModel} from '../../../models/CollectionFilterModel';
import {CategoryTree} from '../CategoryTree/CategoryTree';

export interface FiltersProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  clearFilters: Function;
  shouldShowClearFiltersButton: boolean;
  closeMobileFiltersModal?: Function;
  modalHeaderId?: string;
  isSplitView?: boolean;
}

class FiltersComp extends React.Component<FiltersProps> {
  private getFilterComponent(filterModel: FilterModel) {
    const {isSplitView = false} = this.props;
    switch (filterModel.filterType) {
      case FilterType.COLLECTION:
        return (
          <SingleSelectionFilter
            selected={filterModel.activeOptions}
            filterId={filterModel.filterId}
            options={filterModel.options.map((option) => ({
              id: option.key,
              name: option.value,
            }))}
            isSplitView={isSplitView}
          />
        );
      case FilterType.CUSTOM_COLLECTION:
        return (
          <MultipleSelectionFilter
            filterId={filterModel.filterId}
            selected={[...filterModel.activeOptions]}
            options={filterModel.options.map((option) => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.COLOR_OPTION:
        return (
          <ColorFilter
            selected={[...filterModel.activeOptions]}
            filterId={filterModel.filterId}
            colorOptions={filterModel.options.map((option) => ({key: option.key, value: option.value}))}
          />
        );
      case FilterType.LIST_OPTION:
        return (
          <MultipleSelectionFilter
            selected={[...filterModel.activeOptions, ...filterModel.selectedOptions]}
            filterId={filterModel.filterId}
            options={filterModel.options.map((option) => ({
              id: option.key,
              name: option.value,
            }))}
          />
        );
      case FilterType.PRICE:
        const selectedRange = {
          min: filterModel.activeOptions.minPrice,
          max: filterModel.activeOptions.maxPrice,
        };
        return (
          <RangeFilter
            filterId={filterModel.filterId}
            options={filterModel.options}
            selectedRange={selectedRange}
            key={`${selectedRange.min}-${selectedRange.max}`}
          />
        );
    }
  }

  private readonly hasActiveOptions = (filterModal: IFilterModel) => {
    switch (filterModal.filterType) {
      case FilterType.COLOR_OPTION:
      case FilterType.CUSTOM_COLLECTION:
      case FilterType.LIST_OPTION:
        return !!filterModal.activeOptions.length;
      case FilterType.PRICE:
        return (
          (filterModal.activeOptions.minPrice &&
            filterModal.activeOptions.minPrice !== (filterModal as PriceFilterModel).minPrice) ||
          (filterModal.activeOptions.maxPrice &&
            filterModal.activeOptions.maxPrice !== (filterModal as PriceFilterModel).maxPrice)
        );
    }
  };

  private readonly isExpended = (index: number, filterModel: IFilterModel) => {
    return index === 0 || this.hasActiveOptions(filterModel);
  };

  private getFilterListView(filterModels: FilterModel[]) {
    return (
      <ul className={s.filterList}>
        {filterModels.map((filterModel: FilterModel, index) => (
          <li key={filterModel.filterId} data-hook={`filter-type-${filterModel.filterType}`}>
            <Filter
              title={this.filterTitle(filterModel)}
              filterType={filterModel.filterType}
              expanded={this.isExpended(index, filterModel)}>
              {this.getFilterComponent(filterModel)}
            </Filter>
          </li>
        ))}
      </ul>
    );
  }

  private getCategoryTreeView() {
    const {filterModels} = this.props.globals;
    const collectionFilter = filterModels.find(
      (filterModel: FilterModel) => filterModel.filterType === FilterType.COLLECTION
    ) as CollectionFilterModel;

    return <CategoryTree>{this.getFilterComponent(collectionFilter)}</CategoryTree>;
  }

  private renderFilterList() {
    const {filterModels} = this.props.globals;
    const {isSplitView = false} = this.props;

    if (isSplitView) {
      return this.getFilterListView(
        filterModels.filter((filterModel: FilterModel) => filterModel.filterType !== FilterType.COLLECTION)
      );
    } else {
      return this.getFilterListView(filterModels);
    }
  }

  private filterTitle(filterModel: FilterModel) {
    const {
      globals: {shouldShowMobile},
    } = this.props;
    let title = filterModel.title;

    if (!shouldShowMobile) {
      return title;
    }

    if (Array.isArray(filterModel.activeOptions)) {
      const length = filterModel.activeOptions.length;
      if (length > 0) {
        title += ` (${length})`;
      }
    }

    if (filterModel.activeOptions && (filterModel as PriceFilterModel).activeOptions.minPrice) {
      const filterModelAsPrice = filterModel as PriceFilterModel;
      const minPriceFormatted = filterModelAsPrice.options.find(
        (v) => v.key === filterModelAsPrice.activeOptions.minPrice
      ).value;
      const maxPriceFormatted = filterModelAsPrice.options.find(
        (v) => v.key === filterModelAsPrice.activeOptions.maxPrice
      ).value;
      title += ` (${minPriceFormatted} - ${maxPriceFormatted})`;
    }

    return title;
  }

  public render() {
    const {
      shouldShowClearFiltersButton,
      closeMobileFiltersModal,
      clearFilters,
      isSplitView = false,
      globals: {shouldShowMobile},
    } = this.props;

    return (
      <section className={s.filters}>
        {isSplitView && !shouldShowMobile ? this.getCategoryTreeView() : undefined}
        <FiltersHeader closeMobileFiltersModal={closeMobileFiltersModal} modalHeaderId={this.props.modalHeaderId} />
        {this.renderFilterList()}
        <FiltersFooter
          clearFilters={clearFilters}
          closeMobileFiltersModal={closeMobileFiltersModal}
          shouldShowClearFiltersButton={shouldShowClearFiltersButton}
        />
      </section>
    );
  }
}

export const Filters = withGlobals(withTranslations()(FiltersComp));
