/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './CategoryTree.scss';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export enum DataHook {
  Title = 'category-tree-title',
  Content = 'category-tree-content',
  Container = 'category-tree-container',
}

export interface ICategoryTreeProps extends IGalleryGlobalProps {
  children: React.ReactChild;
}

export const CategoryTree: React.FunctionComponent<ICategoryTreeProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({children, t}: ICategoryTreeProps & IProvidedTranslationProps) => {
    return (
      <div className={s.container} data-hook={DataHook.Container}>
        <h2 data-hook={DataHook.Title} className={s.title}>
          {t('categoryTreeTitle')}
        </h2>
        <div data-hook={DataHook.Content} className={s.content}>
          {children}
        </div>
      </div>
    );
  })
);
