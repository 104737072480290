/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import {ProductList} from '../ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript} from '../../../types/galleryTypes';
import s from './GalleryApp.scss';
import classNames from 'classnames';

import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import stylesParams from '../../../components/GridGallery/stylesParams';
import 'wicg-inert';
import {SideFiltersInLayout} from '../layoutComponents/SideFiltersInLayout/SideFiltersInLayout';
import {SortInLayout} from '../layoutComponents/SortInLayout/SortInLayout';
import {MobileFiltersInLayout} from '../layoutComponents/MobileFiltersInLayout/MobileFiltersInLayout';
import {
  announceIfFilterResultChanged,
  shouldShowFiltersInLayout,
  shouldShowGalleryAppInLayout,
  shouldShowSortInLayout,
} from '../layoutComponents/appUtils';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
  SideFilters = 'gallery-aside-filters',
  MobileContainer = 'mobile-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class GalleryAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  public render() {
    const {hasSelectedFilters, styles} = this.props.globals;
    const isFullWidth = styles.get(stylesParams.full_width);
    const responsive = styles.get(stylesParams.responsive);
    const {shouldShowMobile} = this.props.globals;
    const isDesktop = !shouldShowMobile;

    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const shouldRenderSideFilters = isDesktop && shouldShowFiltersInLayout(this.props.globals);
    const shouldRenderDesktopSort = isDesktop && shouldShowSortInLayout(this.props.globals);

    const classnames = {
      app: classNames({
        [s.galleryApp]: true,
        deviceMobile: shouldShowMobile,
      }),
      content: classNames({
        [s.content]: true,
        [s.contentResponsive]: responsive,
        [s.fullWidth]: isFullWidth,
      }),
      container: classNames({
        [s.container]: true,
        [s.filtersAndProductList]: isDesktop && shouldShowFiltersInLayout(this.props.globals),
      }),
    };

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
          <div data-hook={DataHook.Content} className={classnames.content}>
            {shouldRenderSideFilters && <SideFiltersInLayout {...this.props.globals} />}
            <div data-hook={DataHook.Container} className={classnames.container}>
              {shouldRenderDesktopSort && <SortInLayout />}
              {shouldShowMobile && <MobileFiltersInLayout {...this.props.globals} />}
              {<ProductList hasFilters={hasSelectedFilters} />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const GalleryApp = withGlobals(withTranslations()(GalleryAppComp));
